.hero {
    width: 100%;
    height: 90vh;
    margin-top: 7%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    color: #393939;
    padding: 1%;
    position: relative;
}

.hero .content h1 {
    text-transform: capitalize !important;
    font-size: 45px;
    line-height: 70px;
    font-weight: 600;
}

.hero .content p {
    font-weight: bold;
}

.experience {
    width: 100%;
    position: relative;
    display: flex;
    padding: 1% 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--black);
    color: white;
    cursor: url('https://www.salsoft.net/assets/images/ui/cursor-dark.svg'), default;
}

.experience .content {
    width: 95%;
    position: relative;
}

.experience p {
    cursor: url('https://www.salsoft.net/assets/images/ui/cursor-dark.svg'), default;
    font-size: 16px;
    line-height: 28px;
}

.experience span {
    display: inline-flex;
    justify-content: center;
    font-size: 15px;
    margin-top: -10px;
}

.experience button {
    border-radius: 25px;
    padding: 0.5% 1%;
    background: inherit;
    color: white;
    border: 2px solid white;
    margin-top: 10px;
    font-size: 14px;
}

.insights,
.collaborate,
.careers {
    width: 100%;
    display: flex;
    background: white;
    position: relative;
}

.insights .content,
.careers .content,
.collaborate .content {
    width: 50%;
    padding: 3%;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.insights .content button,
.careers .content button,
.collaborate .content button {
    width: 20%;
    font-size: 14px;
    padding: 1%;
    border: 2px solid var(--black);
    border-radius: 25px;
    background-color: var(--black);
    color: white;
}

.insights .content h2,
.careers .content h2,
.collaborate .content h2 {
    font-size: 30px;
}

.insights .content p,
.careers .content p,
.collaborate .content p {
    font-size: 16px;
}

.insights .image,
.careers .image,
.collaborate .image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.insights .image img,
.careers .image img,
.collaborate .image img {
    width: 80%;
    height: 60%;
    animation: imageAnimation 2s linear;
}

/* @keyframes imageAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

@media (min-width: 1300px) and (max-width:1350px) {}

@media (min-width: 1008px) and (max-width: 1300px) {}

@media (min-width: 641px) and (max-width: 1007px) {
    .hero .content h1 {
        line-height: 25px !important;
    }

    .experience p {
        line-height: 15px;
    }

    .insights,
    .collaborate,
    .careers {
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .industries .stage {
        width: 100%;
    }

    .industries .item {
        /* width: 24%; */
    }
}