.hero {
    width: 100%;
    height: 100vh;
    display: flex;
}

.hero span {
    color: var(--grey);
}

.hero .left,
.hero .right {
    width: 48%;
    padding: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero .right {
    align-items: center;
}

.hero .right img {
    width: 62%;
    height: 60%;
    border-radius: 70%;
    box-shadow: 0px 0px 38px 20px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.randomSection {
    height: 100vh;
    display: flex;
}

.randomSection .right,
.randomSection .left {
    width: 48%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.randomSection .left .imgBorderTop {
    width: 200px;
    height: 200px;
    border-top: 10px solid var(--grey);
    border-right: 10px solid var(--grey);
    position: absolute;
    top: 10%;
    right: 10%;
}

.randomSection .left .imgBorderBottom {
    width: 200px;
    height: 200px;
    border-top: 10px solid var(--grey);
    border-right: 10px solid var(--grey);
    position: absolute;
    bottom: 6%;
    left: 20%;
    transform: rotate(180deg);
}

.randomSection .right {
    padding-right: 100px;
}

.randomSection h1 {
    line-height: 50px;
}

.industries {
    width: 100%;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.industries h1 {
    text-align: center;
    line-height: 50px;
}

.industries .stage {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.industries .stage .item {
    width: 100%;
    display: flex;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    transition: all 0.2s ease;
    margin-bottom: 20px;
    background: white;
    color: var(--black);
    border: 2px solid var(--black);
}

.industries .stage .item:hover {
    background: var(--black);
    border: 2px solid white;
    color: white;
}

.industries .stage .item:hover h2 {
    color: white;
}

.industries .stage .item .left {
    width: 28%;
    padding: 2%;
}

.industries .stage .item .left img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.industries .stage .item .right {
    width: 68%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

@media (min-width: 641px) and (max-width: 1007px) {
    .hero {
        height: auto;
        flex-direction: column;
        padding-top: 90px;
    }

    .hero .left,
    .hero .right {
        width: 50%;
        margin: auto;
    }

    .hero .right img {
        width: 50%;
        height: 200px;
    }

    .randomSection {
        height: auto;
        flex-direction: column-reverse;
    }

    .randomSection .right,
    .randomSection .left {
        width: 50%;
        margin: 20px auto;
    }

    .randomSection .left .imgBorderTop {
        width: 100px;
        height: 100px;
        top: 0;
    }

    .randomSection .left .imgBorderBottom {
        width: 100px;
        height: 100px;
        bottom: -20px;
        left: 15%;
    }
}