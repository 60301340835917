.hero {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    position: relative;
}

.hero h1 {
    color: var(--black);
    display: flex;
    gap: 10px;
}

.hero h1 span {
    display: flex;
}

.slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slider div {
    width: 100%;
    height: 100vh;
}

.slider div img {
    width: 100%;
    height: 100%;
}

.slider button {
    display: none !important;
}

.hero .left {
    width: 48%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero .right {
    align-items: center;
}

.hero .left span {
    color: rgba(0, 0, 0, 1);
}

.hero .left p {
    font-weight: 500;
    text-align: center;
}

.hero .right img {
    width: 70%;
    height: 50%;
    border-radius: 25px;
}

.whyChooseUs {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
}

.whyChooseUs .stage {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.whyChooseUs .stage p {
    text-align: center;
}

.whyChooseUs .stage .item {
    width: 33%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    border-top: 4px solid var(--grey);
}

.whyChooseUs .stage .item p {
    text-align: left;
    word-break: break-all;
}

.whyChooseUs .stage .item i {

    font-size: 40px;
}

.gallery {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.gallery .stage {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.gallery .stage .item {
    width: 15%;
}

.gallery .stage .item img {
    width: 100%;
}

.pagination {
    width: 20%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-self: center;
    margin: 50px 0;
}

.pagination button {
    padding: 1% 5%;
    border-radius: 4px;
    background: white;
    color: var(--black);
    border: none;
    outline: none;
    cursor: pointer;
}

@media (min-width: 1008px) and (max-width: 1300px) {
    .whyChooseUs>p {
        padding: 0 90px;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .hero {
        height: auto;
        padding-top: 90px;
        flex-direction: column;
    }

    .hero .right,
    .hero .left {
        width: 50%;
        margin: auto;
    }

    .whyChooseUs {
        margin: 20px 0;
        height: auto;
    }

    .whyChooseUs .stage {
        margin: 40px 0;
    }

    .whyChooseUs>p {
        padding: 0 80px;
    }
}